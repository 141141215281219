@import "../../assets/scss/variables.scss";

.attempt {
    width: 100%;

    &__list {
        padding: 15px 0 15px;
        &:not(:last-child){
            border-bottom: 1px solid var(--color-border);
        }

        h3 {
            font-size: 18px;
            color: var(--color-text);

        }
    }

    &__phone {
        gap: 30px;
        width: 100%;
        &:not(:last-child){
            margin-bottom: 12px;
        }
        @include max-screen($tablet) {
            justify-content: space-between;
            gap: 10px;
         }
        @include max-screen($mobile) {
           flex-wrap: wrap;
        }
        figure {
            gap: 10px;

            label {
                white-space: nowrap;
                font-weight: 500;
                font-size: 14px;
                color: var(--color-text);

                @include min-screen($tablet) {
                    min-width: 74px;
                }
            }
        }
           
        figcaption {
            a {
                white-space: nowrap;
                color: var(--color-black);
                text-decoration: none;
                font-size: 14px;
               margin-right: 50px;
               min-width: 110px;
                @include max-screen($tablet) {
                    margin-right: 10px;
                }
            }
        }
    }
    &__phoneMain{
        gap: 10px;
    }
    &__copy {
        color: var(--color-button);
    }

    &__status {
        width: 100%;
        
        @include min-screen($mobile) {
            max-width: 195px;
        }
        
        &.normalMode{
            :global {
                .inputSelect .MuiInputBase-root {
                    border-color: var(--color-gray);
                    height: 40px;
    
                    .MuiInputBase-input {
                        font-weight: 600;
                        font-size: 14px;
                        color: var(--color-text);
                        text-align: center;
                    }
                }
                
            }
            &.disabled{
                :global  .inputSelect .MuiInputBase-root {
                      border-color: var(--color-borderDark);
                  }
              }
        }
        &.pillMode{
            :global{
                .MuiInputBase-root.Mui-disabled{
                    background-color: var(--color-white);
                    border-radius: 30px;
                    height: 30px;
                    padding: 0 10px !important;
                    justify-content: center;
                    min-width: 125px;
                }
                .selectValue{
                    font-weight: 500;
                }
                .selectArrow{
                    display: none;
                }
            }
        }
     
       
    }

    &__info {
        width: 530px;

        @include max-screen($desktop) {
            width: 100%;
        }
    }

    &__note {
        width: calc(100% - 530px);

        @include max-screen($desktop) {
            width: 100%;
            padding-left: 0px;
            padding-top: 15px;
        }
    }

    &__btn {
        gap: 10px;
    }

    &__header {
        margin-bottom: 15px;
    }
    .selectStatus{
        background-color: var(--color-white);
        border-radius: 30px;
        cursor: not-allowed;
        border: 1px solid var(--color-borderDark);
        padding: 0 10px;
        height: 30px;
        font-weight: 500;
        font-size: 12px;
        color: var(--color-blue);
        display: inline-flex;
        align-items: center;
        min-width: 125px;
        justify-content: center;
    }
}