* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    scroll-behavior: smooth;
}
body {
    color: var(--color-text);
    font-size: $font-size-base;
    font-family: $font-family-base;
    line-height: $line-height-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: var(--color-active);

}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

[role=link] {
    cursor: pointer;
}

.wrapper {
    padding: 0 60px 45px;
    max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    &.bottom-zero{
        padding-bottom: 0;
    }

    @include max-screen($desktop-large) {
        padding: 0 35px 35px;
    }
    @include max-screen($tablet) {
        padding: 0 15px 35px;
    }
    @include max-screen($mobile) {
        padding: 0 15px 15px;
    }
}

.wrapper-md {
    padding: 0 32px;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;

    @include max-screen($desktop) {
        padding: 0 15px;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}


::-webkit-scrollbar-thumb {
    background: var(--color-borderDark);
    border-radius: 4px;
}

.logo-main {
    width: 192px;
    height: 35px;
    @include max-screen($mobile) {
        width: 130px;
        height: 22px;
     }
}

.logo-icon {
    width: 46px;
    height: 30px;
}

.page-layout {
    min-height: calc(100dvh - 155px);
    @include max-screen($mobile){
        min-height: calc(100dvh - 160px);
    }
}

.disableScroll {
    overflow: hidden;
}
.font-secondary{
    font-family: $font-poppins !important;
}
.font-aeonic{
    font-family: $font-aeonic !important;
}

.font-normal{
    font-weight: 400 !important;
}
.dashboard-wrapper{
    padding-left: 300px;
    @include max-screen($tablet) {
        padding-left: 0;
     }
     @include min-screen($tablet) {
     &.small-sidebar{
        padding-left: 50px;
        .sidebar{
            width: 50px;
            overflow: hidden;
            padding: 80px 5px 24px;
            white-space: nowrap;
            transition: all 0.2s;
            z-index: 9;
            li a {
                padding: 0;
                align-items: center;
                span {
                    min-width: 40px;
                    align-items: center;
                    justify-content: center;
                    padding: 8px;
                }
            }
            .button__text{
                display: none;
            }
            &:hover {
                width: 300px;
                padding: 80px 24px 24px;
    
                .button__text{
                    display: inline-flex;
                }
            }
        }
     }
    }
}
.dashboard-inner{
    padding: 24px;
    min-height: calc(100vh - 110px);
    @include max-screen($mobile) {
        padding: 15px;
    }
}

