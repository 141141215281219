@import '../../../../assets/scss/variables.scss';

.finance {
    padding: 15px;
    &__main{
        position: relative;
    }
    &__left {
        width: calc(100% - 470px);
        margin-right: 20px;
        max-height: calc(100vh - 240px);
        overflow-y: auto;

        @include max-screen($desktop-large) {
            width: 100%;
            margin-right: 0px;
            max-height: none;
        }

    }

    &__right {
        width: 450px;
        max-height: calc(100vh - 240px);
        overflow-y: auto;

        @include max-screen($desktop-large) {
            width: 100%;
            max-height: none;
        }

        :global {
            .MuiAccordion-root {
                box-shadow: 0 0 0;
                background-color: var(--color-active);
                box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
                margin-bottom: 25px;
                border-radius: 8px;

                &::before {
                    display: none;
                }

                .MuiAccordionSummary-content {
                    font-size: 18px;
                    font-weight: 600;
                    color: var(--color-primary);
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .MuiAccordionDetails-root {
                    border-top: 1px solid var(--color-border);
                    padding-top: 20px;
                }
            }
        }
    }

    &__row {
        gap: 10px;
        padding-bottom: 15px;

        .finance__row {
            padding-bottom: 0;
        }
    }

    &__col3 {
        width: calc(50% - 5px);

        @include max-screen($desktop) {
            width: 100%;
        }
    }

    .row {
        gap: 15px;

        &__col {
            width: calc(50% - 12px);

            @include max-screen($smobile) {
                width: 100%;
            }
        }
    }

    &__colInput {
        width: calc(100% - 100px);
    }

    &__colDrop {
        width: 90px;
        padding-top: 27px;
    }
  
    &__Save {
        padding-top: 20px;
    }

    &__SaveButton {
        max-width: 200px;
        width: 100%;
    }
    .spaceTop {
        padding-top: 27px;
    }
   
}