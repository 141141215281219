.stepsDialog {
    .MuiPaper-root {
        max-width: 400px;
        border-radius: 8px;
        width: 100%;

        @include max-screen($mobile) {
            margin: 0;
            max-height: 100%;
            border-radius: 0;
            height: 100%;
            max-width: 100%;
        }

        .closeDialog {
            color: var(--color-gray);
            width: 28px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 16px;
            top: 16px;
            z-index: 9;
        }

        .dialogWrapper {
            padding: 24px;
            &__header{
                margin-bottom: 15px;
                h2{
                    font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
                }
            }
            &__table{
                border: 1px solid var(--color-border);
                border-radius: 4px;
                table{
                    width: 100%;
                    border-collapse: collapse;
                    tr{
                        td{
                            font-size: 14px;
                            font-weight: 500;
                            color: var(--color-textLight);
                            font-family: $font-family-base;
                            text-align: left;
                            border-bottom: 1px solid var(--color-border);
                            border-right: 1px solid var(--color-border) !important;
                            padding: 10px;
                            &:last-child{
                                border-right: 0 !important;
                            }
                        }
                        th{
                            color: var(--color-primary);
                            text-align: left;
                            font-size: 14px;
                            padding: 10px;
                            border-bottom: 1px solid var(--color-border);
                            border-right: 1px solid var(--color-border) !important;
                            &:last-child{
                                border-right: 0 !important;
                            }
                        }
                        
                    }
                    tbody{
                        tr{
                            &:last-child{
                                td ,th{
                                border-bottom: 0 !important;
                            }
                            }  
                        }
                    }
                }
            }
            &__info{
                margin-bottom: 16px;
                p{
                    color: var(--color-textLight);
                    font-size: 14px;
                    margin-bottom: 16px;
                }
                ul{
                    padding-left: 16px;
                    margin-bottom: 16px;
                    li{
                        list-style: disc;
                        color: var(--color-textLight);
                        font-size: 14px;
                    }
                }
                h3{
                    font-size: 16px;
            font-weight: 600;
            color: var(--color-text);
            margin-bottom: 4px;
                }
            }

        }
    }
   
    &.dialogLarge {
        .MuiPaper-root {
            max-width: 640px;

            @include max-screen($mobile) {
                max-width: 100%;
            }
        }
    }
    &.showPurchaseModal{
        .MuiPaper-root {
            max-width:480px;
            padding: 0;
            @include max-screen($mobile) {
                max-width: 100%;
            }
            .closeDialog {
                right: 24px;
                top: 24px;
            }
        }
    }
    .mainCol {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        &__half {
            width: 50%;
            padding: 0 10px;

            @include max-screen($mobile) {
                width: 100%;
                margin-bottom: 15px;
            }
            .inputGroup{
            margin-bottom: 15px;
            }
        }

    }
  .actions{
    margin-top: 32px;
  }

}

.my-15{
    margin: 15px 0;
}
.mb-0{
    margin-bottom: 0 !important;
}
.mb-15{
    margin-bottom: 15px;
}




  
  
    
   