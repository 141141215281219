@import '../../assets/scss/variables.scss';
.userDetail{
&__loader{
    position: relative;
    min-width: 195px;
}
    &__row{
        width: 100%;
        padding-bottom: 10px;
        label{
            font-size: 14px;
            font-weight: 500;
            color: var(--color-button);
            display: block;
            width: 80px;
        }
        p{
            text-align: left;
        width: calc(100% - 80px);
        color: var(--color-textGray);
        }
    }
    &__btn{
        margin-top: 15px;
    }
   
}
.emilList{
    background-color:  $color-lightButton;
}
.buttonSpace{
    min-width: 105px;
}