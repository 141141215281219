@import '../../assets/scss/variables.scss';
.cancel {
    padding: 24px;
    @include max-screen($mobile){
        padding: 15px;
    }

    &__header {
        margin-bottom: 16px;

        svg {
            width: 48px;
            height: 48px;

            &:nth-child(2) {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        }
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-text);
    }
   &__text {
    p {
        font-size: 14px;
        margin-bottom: 15px;
        color: var(--color-textLight);
    }
   }
    &__item {
        margin: 12px 0;
        padding: 12px 0;
        border-bottom: 1px solid var(--color-borderDark);
        &:last-of-type {
            margin-bottom: 0;
        }
        svg {
            width: 20px;
        }
        aside {
            font-size: 14px;
            line-height: 1.4;
            padding-left: 16px;
            width: calc(100% - 20px);

            strong {
                font-weight: 600;
                color: var(--color-button);
            }

            p {
                color: var(--color-textLight);
            }
        }
    }
    &__button {
        padding-top: 10px;
        button {
            flex: 1;
            &+button {
                margin-left: 8px;
            }
        }
    }
}

:global {
    .stepsDialog.welcomeDialog.cancelSubscription .MuiPaper-root {
        @include min-screen($mobile){
            max-width: 600px;
        }
    }
}