@import '../../assets/scss/variables.scss';

.note {
    height: 100%;
    :global {
        .rdw-editor-toolbar {
            position: absolute;
            z-index: 2;
            bottom: 2px;
            border: 0;

            @include max-screen($smobile) {
                position: static;
            }
        }

        .rdw-editor-wrapper,
        .DraftEditor-root {
            min-height: 142px;
            overflow-y: auto;
        }

        .DraftEditor-root {
            @include min-screen($smobile) {
                padding-bottom: 30px;
            }
        }

        .rdw-editor-wrapper {
            @include max-screen($smobile) {
                padding-top: 20px;
            }
        }
    }

    &__add {
        border-radius: 8px;
        border: 1px solid var(--color-gray);
        padding: 0 14px 9px;
        background-color: var(--color-white);
        position: relative;
        height: 100%;
        textarea {
            width: 100%;
            border: 0;
            resize: none;
            height: 100%;
            padding-top: 15px;
            font-family: $font-family-base;
            outline: 0;
            font-size: 16px;
            &::placeholder{
                color: $color-textLight;
            }
        }
    }

    &__summaryText {
        border-radius: 8px;
        padding: 14px;
        font-size: 16px;
        color: var(--color-text);
        background-color: #D8E4FF;

        ul{
            list-style-type: disc;
            padding-left: 20px;
        }
        ol{
            list-style-type:decimal;
            padding-left: 20px;
        }
    }

    &__header {
        margin-bottom: 4px;

        h5 {
            font-size: 16px;
            font-weight: 700;
            padding-left: 14px;
            color: var(--color-button);
        }

        p {
            font-size: 12px;
            color: var(--color-textLight);
        }
    }

}