@import '../../assets/scss/variables.scss';

.page404{
    background-color: var(--color-white);
    min-height: 100vh;
    display: flex;
    align-items: center;
    :global{
        .wrapper{
            max-width: 1280px;
        }
    }
   
    &__inner{
        padding: 96px 0;
        min-height: calc(100vh - 155px);
        gap: 32px;
        @include max-screen($mobile) {
           flex-direction: column-reverse;
           text-align: center;
           justify-content: center;
         }
        figure, figcaption{
            width: calc(50% - 32px);
            @include max-screen($mobile) {
                width: 100%;
             }
            h2{
                font-size: 60px;
                font-weight: 600;
                margin-bottom: 24px;
                color: var(--color-text);
                @include max-screen($mobile) {
                    font-size: 42px;
                    margin-bottom: 0;
                 }
            }
            p{
                font-size: 20px;
                color: var(--color-textLight);
                max-width: 560px;
                margin-bottom: 48px;
                @include max-screen($mobile) {
                    font-size: 16px;
                    max-width: 100%;
                    margin-bottom: 0;
                 }
            }

        }
        figure{
            padding-right: 32px;
            @include max-screen($mobile) {
                padding-right: 0;
             }
            img{
                max-width: 100%;
            }
        }
    }
}