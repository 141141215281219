@import '../../assets/scss/variables.scss';
.map{
    height: 471px;
    overflow: hidden;
    position: relative;
    @include max-screen($tablet) {
        height: 350px;
    }
    @include max-screen($mobile) {
        height: 250px;
    }
    &__default{
        height: 100%;  
    }
    &__SliderImage{
        height: 100%;
        position: relative;
       img{
        width: 100%;
        height: 100%;
        object-fit: cover;
       }
    }
    &__back{
        position: absolute;
        top:10px;
        left: 10px;
        z-index: 2;
    }
    &__Slider{
        width: 60%;
        overflow: hidden;
        position: relative;
        :global {
            .slick-slider {
                height: 100%;
                .slick-list{
                    height: 100%;
                }
                .slick-track {
                    display: flex !important;
                    height: 100%;
                    .slick-slide {
                        height: inherit !important;
    
                        &>div {
                            height: 100%;
                        }
                    }
                 
                }
            }
            .slick-disabled{
                display: none !important;
            }
            .slick-slide {
                padding: 0;
            }

            .slick-next {
                right: 0;
            }

            .slick-prev {
                left: 0;
                z-index: 2;
            }

            .slick-arrow {
                background-color: transparent;
                color: $color-white !important;
                width: 70px;
                    height: 70px; 
                    @include max-screen($mobile) {
                        width: 30px;
                        height: 30px; 
                    }
                svg{
                    width: 70px;
                    height: 70px;  
                    @include max-screen($mobile) {
                        width: 30px;
                        height: 30px;  
                    }
                }

                &::before {
                    display: none;
                }
            }
        }
    }
    &__defaultImages{
        width: 0;
        transition: all 0.5s;
        overflow-y: auto;
        &.showList{
            width: 40%;
        }
    }
    &__defaultMap{
        width: 40%;
        transition: all 0.5s;
        &.hideMap{
            width: 0;
        }
    }
    &__ListImage{
        width: 50%;
        height: 160px;
        position: relative;
        @include max-screen($mobile) {
            height: 60px;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            display: block;
        }
    }
    &__street{
        width: 0;
        height: 471px;
    }
    &__img{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__button{
        position: absolute;
        left: 10px;
        bottom: 20px;
        z-index: 2;
        gap: 5px;
        :global{
            .button{
                .button__text{
                    @include max-screen($mobile) {
                       display: none;
                    } 
                }
               
            }
        }
    }
    .hide{
        width: 0;
    }
    .full{
        width: 100%;
    }
}