.filterMenu {
    &__info {
        padding: 20px;
    max-height: 450px;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    }
    p {
        font-size: 14px;
        color: var(--color-text);
        margin-bottom: 5px;
        font-weight: 500;
    }

    .filterRow {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 5px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &__col {
            width: calc(50% - 10px);
            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }

    &__footer {
        padding: 16px;
        border-top: 1px solid var(--color-border);
        background-color: var(--color-white);
    flex: 0;
    }

    .colRow {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 12px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &__col {
            width: calc(50% - 6px);
            @include max-screen($smobile) {
                width: 100%;
            }
        }
        &__full{
            width: 100%;
        }
    }
.separate{
    line-height: 2.5;
}
    .accordion {
        .MuiAccordion-root {
            width: 100% !important;
            margin: 0;
            border: 0;
            box-shadow: 0 0 0;
            max-height: 100% !important;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &:before {
                display: none;
            }

            .MuiAccordionSummary-root {
                padding: 0;
                min-height: 36px;
                flex-direction: row-reverse;
                align-items: center;
                gap: 20px;

                .MuiAccordionSummary-content {
                    margin: 0;

                    p {
                        margin: 0;
                        font-size: 16px;
                        font-weight: 500;
                        font-family: $font-family-base;
                    }
                }

                .MuiAccordionSummary-expandIconWrappe {
                    color: var(--color-grayLight);
                }
            }

            .MuiAccordionDetails-root {
                padding: 5px 44px;
                @include max-screen($mobile) {
                    padding: 5px;
                }
            }
        }
    }

    .datePicker {
        margin-bottom: 0;

        .MuiInputBase-root {
            max-width: 100%;

            .MuiInputBase-input {
                min-height: 27px;
                width: auto;
                @include max-screen($mobile) {
                    width: 100%;
                }
            }
        }

        .MuiFormLabel-root {
            top: -5px;
            left: 37%;
            @include max-screen($mobile) {
                left: 27px;
            }
        }
    }
.switchLabel{
        &__label{
            margin-left: 8px;
        }
        .MuiFormControlLabel-root {
            align-items: flex-start;
        }
        .MuiCheckbox-root{
            padding-top: 6px;
        }
        h5 {
            font-size: 14px;
            font-weight: 400;
            color: var(--color-text);
        }

        p {
            font-size: 12px;
            font-weight: 400;
            color: var(--color-button);
        }
    
}
}
.filterMenuFooter {
    padding: 16px;
    width: 100%;
    gap: 12px;
    border-top: 1px solid var(--color-border);

    &__half {
        width: calc(50% - 6px);
    }
}