@import '../../assets/scss/variables.scss';
.mapInfo {
    position: relative;
    width: 100%;
    max-width: 544px;
    margin-bottom: 25px;

    &__inner {
        position: relative;
        overflow: hidden;
        padding: 20px 32px;
        background-color: var(--color-white);
        border-radius: 12px;
        box-shadow: $shadow2;
        gap: 16px;

        @include max-screen($mobile) {
            flex-direction: column;

        }

        figure {
            position: relative;
            z-index: 1;

            &:before,
            &:after {
                content: "";
                width: 216px;
                height: 216px;
                display: block;
                position: absolute;
                top: -77px;
                left: -80px;
                z-index: 1;
                pointer-events: none;
                background-color: var(--color-white);

            }

            &:after {
                @include background-image(animated-circle, gif, center center, no-repeat, 216px);
                width: 216px;
                height: 216px;
                mix-blend-mode: difference;
                opacity: 5%;

            }

            svg {
                position: relative;
                z-index: 2;
                width: 58px;
                height: 58px;
                padding: 10px;
                border-radius: 100%;
                border: 8px solid var(--color-greenBg);
                background: var(--color-greenLight);
                color: var(--color-green);
            }
        }

        figcaption {
            position: relative;
            z-index: 2;
            width: calc(100% - 100px);

            @include max-screen($mobile) {
                width: 100%;
                text-align: center;
            }

            h2 {
                font-size: 18px;
                color: var(--color-text);
                font-weight: 600;
                margin-bottom: 4px;
                font-family: $font-family-base;
            }

            p {
                font-size: 14px;
                color: var(--color-textLight);
                font-family: $font-family-base;
                white-space: nowrap;
                line-height: 1.5;
                @include max-screen($tablet) {
                    white-space: inherit;
                     }
            }
        }

    }

    &__close {
        position: absolute;
        right: 16px;
        top: 20px;
        color: var(--color-gray);
        z-index: 9;
        opacity: 0.7;
    }
.mapArrow{
    position: relative;
    width: 100%;
    z-index: 1;
    &::after,
    &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
        border-top: 25px solid var(--color-white);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    &::before {
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 27px solid var(--color-white);
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 27px solid rgb(0 0 0 / 10%);
        top: 7px;
        filter: blur(5px);
    }
}


}