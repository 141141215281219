@import '../../assets/scss/variables.scss';
.billing{
    border-radius: 12px;
    border: 1px solid var(--color-border);
    background: var(--color-white);
    box-shadow: $shadow;
    display: flex;
    padding: 15px;
    gap: 8px;
    flex: 1 0 0;
    max-width: 500px;
    @include max-screen($desktop-xlarge) {
        padding: 15px;
     }
     @include max-screen($mobile) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
       max-width: 100%;
     }
  
    p {
      color: var(--color-textLight);
      font-size: 14px;
      font-weight: 500;
      @include max-screen($mobile) {
        text-align: center;
      }
    }
  
    h2 {
      font-size: 22px;
      font-weight: 600;
      @include max-screen($desktop-xlarge) {
        font-size: 20px;
     }
     @include max-screen($desktop-large) {
        font-size:18px;
     }
}
&__history{
    padding: 20px 0;
    width: 100%;
    gap: 15px;
   
   
}
&__historyTable{
    :global{
        .custom-table{
            .custom-table-wrapper{
                max-height: calc(100vh - 345px) !important;
            }
        } 
        .noData {
            min-height: calc(100vh - 390px) !important;
        }
        
    }
}
}
.refundIcon{
    svg{
        width: 20px;
        height: 20px;
        color: var(--color-primary);
    }
    
}