@import '../../assets/scss/variables.scss';

.header {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-border);
    padding: 12.5px 60px;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;

    @include max-screen($desktop-large) {
        padding: 12.5px 35px;
    }

    @include max-screen($tablet) {
        padding: 12.5px 15px;
    }

    @include max-screen($mobile) {
        padding: 5px 15px;
    }

&__logo{
    display: flex;
    @include max-screen($mobile) {
        width: 130px;
     }
    img{
        max-width: 100%;
    }
}
&__right{
    @include max-screen($mobile) {
        width: calc(100% - 150px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
     }
    
}
&.headerLoggedIn{
    padding: 12.5px 30px;
    @include max-screen($tablet) {
        padding: 12.5px 15px;
     }
}
}
.headerSpace{
    height: 66px;
    @include max-screen($mobile) {
        height: 50px;
    }
}
