@import '../../assets/scss/variables.scss';
.campaigns{
    &__filters{
        gap: 10px;
        margin-bottom: 15px;
    }
    &__search{
        width: calc(100% - 775px);
        @include max-screen($desktop-large){
            width: 100%;
        }
    }
    &__table{
        :global{
            .custom-table .custom-table-wrapper {
                max-height: calc(100vh - 245px)!important;
            }
           .noData {
                min-height: calc(100vh - 282px) !important;
            }
        }
    }
}
