@import '../../../../assets/scss/variables.scss';

.linked {
    width: 100%;
padding: 15px 15px 0;
    .cards {
        gap: 15px;
        margin-bottom: 15px;
       

        .card {
            width: calc(33.3% - 12px);

            @include max-screen($tablet) {
                width: calc(50% - 18px);
            }

            @include max-screen($smobile) {
                width: 100%;
            }
        }
    }
    .table{
    .tableHeading{
        margin-bottom: 18px;
        h2{
            font-size: 18px;
            font-weight: 600;
            color: var(--color-primary);
        }
        p{
            font-size: 12px;
            font-weight: 400;
            color: var(--color-textLight);
        }
    }
       
        .tableInner{
            position:relative;
            
        }
    }
}