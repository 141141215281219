@import '../../assets/scss/variables.scss';

    .heroSection {
        text-align: center;
        color: var(--color-black);
        padding: 240px 83px 113px;
        font-family: $font-poppins;
        @include max-screen($tablet) {
            padding: 150px 16px;
         }
         @include max-screen($mobile) {
            padding: 150px 16px 48px;
        }
        @include background-image(hero-bg, png, center 77px, no-repeat, auto 450px );
      
      
        &__inner {
            margin: 0 auto;
            max-width: 1271px;
            @include max-screen($mobile) {
                max-width: 340px;
            }
            ul{
                gap: 64px;
                margin:28px 0 48px;
                @include max-screen($tablet) {
                    gap: 20px;
                 }
                @include max-screen($mobile) {
                    flex-direction: column;
                    gap: 10px;
                    margin:16px 0;
                    display: inline-flex;
                }
                li{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 18px;
                    @include max-screen($mobile) {
                        font-size: 16px;
                    }
                    svg{
                        width: 32px;
                        height: 32px;
                        background-color: var(--color-grayLight2);
                        border-radius: 100%;

                    }
                }
            }
    
            h1 {
                font-size: 95px;
                font-weight: 700;
                line-height: 120%;
                @include max-screen($desktop) {
                    font-size: 75px;
                    line-height: 1.2;
                 }
                @include max-screen($tablet) {
                    font-size: 52px;

                 }
                 @include max-screen($stablet) {
                    font-size: 36px;
                    letter-spacing: -1.44px;
                 }
                 span{
                  
                        background: var(--Text-highlight, linear-gradient(90deg, #5F44FF 42.74%, #1CC8FF 81.97%));
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;

            
                 }
              
            }
    
        p{
            margin-top: 20px;
            font-size: 18px;
        }
    
            
        }
   
   
    
    }

