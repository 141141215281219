@import '../../assets/scss/variables.scss';

.chat {
  
    position: relative;
    &.smartChat{
        padding: 16px 0 53px;
    }

    &__addNote {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &::after {
            content: "";
            background-color: $color-white;
            opacity: 0.4;
            width: 100%;
            left: 0;
            top: 0;
            height: 100%;
            position: absolute;
            z-index: 0;
        }
    }

    &__addNoteSend {
        position: absolute;
        right: 45px;
        bottom: 20px;
        border-radius: 8px;
        background: linear-gradient($color-white, $color-white) padding-box, $gradient8 border-box;
        border: 1px solid transparent;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--color-primary);

        svg {
            width: 16px;
            color: var(--color-gray);
        }
        :global{
            .MuiCircularProgress-root{
                width: auto !important;
                height: auto !important;
                margin-top: 5px;
                svg {
                    color: var(--color-primary);
                }
            }
        }
    }

    &__addNoteEditor {
        border-radius: 8px;
        background: linear-gradient($color-white, $color-white) padding-box, $gradient8 border-box;
        border: 1px solid transparent;
        overflow: hidden;

        padding: 12px 14px;

        :global {
            .rdw-editor-wrapper {
                display: flex;
                flex-direction: column-reverse;
                height: 165px;
            }

            .rdw-editor-toolbar {
                border: 0;
            }
        }
    }

    &__notes {
        &.active {
            filter: blur(4px);
        }
    }

    &__addNoteTextarea {
        max-width: 961px;
        margin: 0 auto;
        position: relative;
        z-index: 2;
        padding: 30px 15px 0;

        textarea {
            width: 100%;
            resize: none;
            min-height: 62px;
            padding: 12px 14px;
            font-size: 16px;
            font-family: $font-family-base;
            color: var(--color-text);
            border-radius: 8px;
            box-shadow: $shadow10;
            background: linear-gradient($color-white, $color-white) padding-box, $gradient8 border-box;
            border: 1px solid transparent;
            outline: none;

            &::placeholder {
                color: var(--color-gray);
            }
        }
        :global{
            .public-DraftStyleDefault-ol, .public-DraftStyleDefault-ul li {
                padding-left: 20px;
            }
        }
    }

    &__header {
      padding-bottom: 10px;
        &.smartSummary{
            padding: 0 36px 0 100px;
            @include max-screen($tablet) {
                padding: 0 15px;
            }
            @include max-screen($mobile) {
                padding: 0;
            }
           
        }

        @include max-screen($tablet) {
            padding: 0 15px;
        }

        @include max-screen($mobile) {
            padding: 0;
        }

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);

        }

        :global {
            .button {
                width: 32px;
                height: 32px;
                min-height: 32px;
                border-radius: 8px;
                padding: 0;
                gap: 0;

                svg {
                    width: 16px;
                    height: 16px;
                    color: var(--color-gray);
                }
            }
        }
    }

  

  

    &__loader {
        min-height: 150px;
    }

    &__list {
        max-height: 275px;
        overflow-y: auto;
        padding-right: 10px;
        &.smartSummaryList{
            padding: 0 100px;
            max-height: 315px;
            @include max-screen($tablet) {
                padding: 0 15px;
            }
    
            @include max-screen($mobile) {
                padding: 0;
            }
        }
    }


}
.message{
        width: 100%;
        gap: 10px;
        margin-top: 26px;

        &.messageReceiver {
            flex-direction: row-reverse;

            .message__textInfoHeader {
                flex-direction: row-reverse;
                width: 100%;
                max-width: 90%;
            }

            .message__textInfoText {
                border-radius: 8px 0 8px 8px;
                text-align: right;
                width: 100%;
                max-width: 90%;
                direction: rtl;
                ul, ol{
                    padding-right: 20px;
                }
            }
            .message__textInfo{
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                align-items: flex-end;
            }
        }
        &__textImg {
            figure {
                @include round-image(40px);
                background: $gradient9;
                color: var(--color-white);
            }
    
        }
        &__textInfo {
            width: 100%;
        }
    
        &__textInfoHeader {
            p {
                font-size: 14px;
                font-weight: 500;
                color: var(--color-button);
                gap: 10px;
              
            }
        }
        &__textInfoIcon{
            display: inline-flex;
            svg{
                width: 15px;
                height: 15px;
            }
        }
    
        &__textInfoText {
            padding: 10px;
            margin-top: 6px;
            border-radius: 0px 8px 8px 8px;
            background: linear-gradient($color-white, $color-white) padding-box, $gradient8 border-box;
            border: 1px solid transparent;
            color: var(--color-text);
            font-size: 16px;
            ol,
                ul {
                    padding-left: 20px;
                    list-style: auto;
                }

                ul {
                    list-style-type: disc;
                }
        }
}