@mixin max-screen($screenSize) {
    @media only screen and (max-width: $screenSize) {
        @content;
    }
}

@mixin min-screen($screenSize) {
    @media only screen and (min-width: ($screenSize + 1)) {
        @content;
    }
}

@mixin background-image($img, $ext, $pos, $repeat: no-repeat, $size: auto) {
    background-image: url(#{$imgPath}#{$img}.#{$ext});
    background-size: $size;
    background-position: $pos;
    background-repeat: $repeat;
}

@mixin round-image($imgSize) {
    width: $imgSize;
    height: $imgSize;
    min-width: $imgSize;
    object-fit: cover;
    border-radius: 50%;
}

@mixin line-clamp($clamp) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $clamp;
    -webkit-box-orient: vertical;
}