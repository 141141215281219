@import '../../../../assets/scss/variables.scss';
.mls {
    width: 100%;
    padding: 15px 15px 0;
    .row {
        gap: 15px;

        &__col {
            width: calc(20% - 12px);
            @include max-screen($tablet) {
                width: calc(33.3% - 12px);
            }
            @include max-screen($mobile) {
                width: calc(50% - 12px);
            }
            @include max-screen($smobile) {
                width: 100%;
            }
        }
    }
}