.badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    align-items: center;
    box-shadow: $shadow;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 16px;
    color: var(--color-button);
    background-color: var(--color-white);
    border: 1px solid var(--color-borderDark);
    .leads-pill &{
        border-radius: 6px;
    }

    span {
        display: flex;
        align-items: center;
        gap: 4px;

        svg {
            color: var(--color-green);
        }
    }

    &.calling {
        color: var(--color-greenDark);
        background-color: var(--color-greenBg);
        border: 1px solid var(--color-greenBorder);
    }

    &.off,
    &.processing {
        color: var(--color-badgeText);
        background-color: var(--color-badgeBg);
        border: 1px solid var(--color-borderDark);
    }
    &.reset{
        color: var(--color-blue);
        background-color: var(--color-blueLight);
        border: 1px solid var(--color-blueBorder);
        font-size: 14px;
        padding: 0.5px 10px;
        @include max-screen($desktop) {
            line-height: 1;
            font-size: 10px;
            min-height: 22px;
         }
        svg {
            color: var(--color-blue);
            width: 14px;
            height: 14px;
            @include max-screen($desktop) {
                width: 10px;
            height: 10px;
             }
        }
    }

    &.scheduled {
        color: var(--color-warningText);
        background-color: var(--color-warningBg);
        border: 1px solid var(--color-warning);
      
    }

    &.completed {
        color: var(--color-blue);
        background-color: var(--color-blueLight);
        border: 1px solid var(--color-blueBorder);
        svg {
            color: var(--color-blue);
        }
    }

    &.blue-dark {
        border: 1px solid var(--color-gray);
        color: var(--color-badgeText);

        svg {
            color: var(--color-badgeText);
        }
    }

    &.blue-bright {
        border: 1px solid var(--color-blueBright);
        color: var(--color-blueBright);
        svg {
            color: var(--color-blueBright);
        }
    }

    &.blue-light {
        border: 1px solid var(--color-borderDark);
        background-color: var(--color-blueLight);
        color: var(--color-blueBright);
        svg {
            color: var(--color-blueBright);
        }
    }

    &.red {
        border: 1px solid var(--color-errorBorder);
        color: var(--color-error);
        background-color: var(--color-errorBg);
        svg {
            color: var(--color-error);
        }
    }

}