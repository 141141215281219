@import '../../assets/scss/variables.scss';
.addRole{
&__checkbox{
    margin-bottom: 15px;
    label{
        font-size: 14px;
        font-weight: 500;
        color: var(--color-button);
        display: flex;
        margin-bottom: 6px;
        gap: 6px;
    }
}
&__checkboxList{
    gap: 10px;
}
&__checkboxes{
width: calc(50% - 10px);
}
&__error{
    color: var(--color-warningText);
}
}