@import '../../../../assets/scss/variables.scss';
.calculator{
    gap: 10px;
    &__top{
        padding: 15px;
    }
        &__col{
            width: calc(33.3% - 7px);
            @include max-screen($mobile) {
                width: 100%;
            }
        }
        &__colFour{
            width: calc(25% - 8px);
            @include max-screen($desktop-large) {
                width: calc(50% - 5px);
            }
            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }