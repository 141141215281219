@import '../../assets/scss/variables.scss';
.infoCard{
    h4{
        font-size: 16px;
        color: var(--color-text);
        margin-bottom: 10px;
        font-weight: 600;
    }
    p{
        font-size: 14px;
        color: var(--color-textLight);
    }
}