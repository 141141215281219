.flex {
    display: flex;
}

.dflex {
    display: flex;
    flex-wrap: wrap;
}

.alignCenter {
    align-items: center;
}

.alignStart {
    align-items: flex-start;
}

.alignEnd {
    align-items: flex-end;
}

.justifySpaceBetween {
    justify-content: space-between;
}

.justifyCenter {
    justify-content: center;
}

.justifyEnd {
    justify-content: flex-end;
}

.flexCol {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.inlineFlex {
    display: inline-flex;
}
.gap-10{
gap: 10px;
}