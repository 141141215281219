@import '../../assets/scss/variables.scss';

.shadowBox {
    background-color: var(--color-active);
    box-shadow: $shadow6;
    margin-bottom: 15px;

    &__header {
        padding: 15px;
        border-bottom: 1px solid var(--color-border);
        gap: 15px;
        @include max-screen($tablet) {
            padding: 15px;
        }
        .leftInfo{
            h3{
                font-size: 18px;
                font-weight: 600;
                color: var(--color-black);
                display: flex;
                align-items: center;
                gap: 10px;
                span{
                    display: inline-flex;
                    color: var(--color-gray);
                    align-items: center;
                    white-space: nowrap;
                    gap: 5px;
                    font-size: 12px;
                    font-weight: 400;
                    svg{
                        width: 18px;
                        height: 18px;
                    }
                    &.failed{
                        color: var(--color-black);
                    }
                }
            }
            p{
                font-size: 14px;
                color: var(--color-black);
            }
        }
        .spin{
            animation-name: spin;
            animation-duration: 4000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
        @keyframes spin {
            from {transform:rotate(0deg);}
            to {transform:rotate(360deg);}
        }
        
        .rightInfo{
            text-align: right;
            @include max-screen($mobile) {
               width: 100%;
               text-align: left;
            }
            h3{
                font-size: 20px;
                color: var(--color-link);
               
            }
            p{
                font-size: 14px;
                color: var(--color-link);
            }
        }
    }

    &__info {
        padding: 15px 30px;
        @include max-screen($tablet) {
            padding: 15px;
        }
    }
}