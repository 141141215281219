@import '../../assets/scss/variables.scss';
.filterMenu{
    :global{
        .MuiPaper-root{
            border-radius: 12px;
            margin-top: 5px;
            border: 1px solid var(--color-border);
            box-shadow: $shadow5;
            width: 328px;
            max-height: 450px;
        }
        .MuiList-root{
        padding: 0;
        }
    }
   &.filterLarge{
    :global{
        .MuiPaper-root{
            width: 771px;
            max-height: 100%;
            overflow: visible;
        }
        }
    }
    &.filterList{
        :global{
            .MuiPaper-root{
                width: 160px;
                max-height: 100%;
                overflow: visible;
            }
            }
        }
   }