@import '../../assets/scss/variables.scss';

.sideBar {
    background-color: var(--color-white);
    width: 300px;
    position: fixed;
    height: 100%;
    left: 0;
    box-shadow: $shadow;
    padding: 80px 24px 24px;
    display: flex;
    top: 0px;
    flex-direction: column;
 
    @include max-screen($tablet) {
        padding: 24px;
     }
    ul {
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
            a {
                display: flex;
                font-size: 16px;
                font-weight: 600;
                color: var(--color-links);
                text-decoration: none;
                border-radius: 6px;
                align-items: center;

                span {
                    display: inline-flex;
                    color: var(--color-gray);
                    min-width: 40px;
                    align-items: center;
                    justify-content: center;
                    padding: 8px;
                }
                figcaption{
                    padding: 8px;
                }
                &:hover{
                    background-color: var(--color-border);
                }
            }
            :global{
                .active{
                    background-color: var(--color-border);
                }
            }
            &.sideBardisable{
                cursor: not-allowed;
                opacity:0.6; 
                &:hover{
                    a{
                        pointer-events: none;
                         background-color: transparent;
                    }
                };
            }
        }
    }
    &__logo {
        margin-bottom: 30px;
        display: none;
        @include max-screen($tablet) {
          display: flex;
         }
         :global .logo-main{
            width: 180px;
            height: 28px;
         }
    }
    &__nav {
        display: flex;
        flex: 1;
        justify-content: space-between;
        flex-direction: column;
    }
    &__bottom{
        display: flex;
        flex-direction: column;
        gap: 10px;
        text-align: center;
    }
}
