@import '../../../../assets/scss/variables.scss';
.tax {
    width: 100%;
padding: 15px 15px 0;
    .row {
        gap: 15px;

        &__col {
            width: calc(16.6% - 12px);
            @include max-screen($desktop-large) {
                width: calc(33.3% - 12px);
            }
            @include max-screen($mobile) {
                width: 100%;
            }
        }
    }
}