@import '../../assets/scss/variables.scss';

.profileMenu {
    gap: 10px;
    &__item {
        font-size: 14px;
        font-weight: 600;
        gap: 6px;
        display: flex;
        align-items: center;

        @include max-screen($tablet) {
            display: none;
        }
    }
    .userName{
        text-overflow: ellipsis;   
        overflow:hidden;           
        white-space:nowrap;           
        max-width: 120px;
        font-weight: 400;
        color: var(--color-textLight);
        font-size: 12px;
        display: flex;
        flex-direction: column;
        text-align: center;
        line-height: 1.4;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
       
        span{
            font-weight: 600;
            color: var(--color-primary);
            font-size: 12px;
           
        }
    }

    

}
