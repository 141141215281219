@import '../../assets/scss/variables.scss';

.log {
    background-color: var(--color-bgLight);
    width: 50vw;
    box-shadow: $shadow8;
    margin: 8px 0 0 8px;
    height: calc(100% - 66px);
    @include max-screen($tablet) {
        width: 100%;
        margin: 0;
    }
    @include max-screen($mobile) {
        width: 100%;
        margin: 0;
        height: 100%;
    }

    &__header {
        padding: 10px 10px 10px 25px;
        gap: 5px;
        border-bottom: 1px solid var(--color-border);

        h2 {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-button);
        }

        span {
            display: inline-flex;
            width: 28px;
            height: 28px;
            align-items: center;
            justify-content: center;
            color: var(--color-grayLight);
        }
    }
    &__body{
        padding: 10px;
    }
}
:global {
    .LogDrawer {
        pointer-events: all;
        .MuiDrawer-paper {
            background-color: transparent;
            box-shadow: 0 0 0;
            display: flex;
            justify-content: flex-end;
            border: 0;
            bottom: 0;
            
            @include max-screen($tablet) {
                width: 100%;
            }
           
        }

        &.MuiDrawer-root {
            .MuiBackdrop-root {
                background-color: transparent !important;
            }
        }
        .infinite-scroll-component__outerdiv{
            pointer-events: all;
            @include max-screen($mobile) {
                height: 100%;
            }
            .infinite-scroll-component{
                @include max-screen($mobile) {
                    height: 100% !important;
                }
            }
        }
        .custom-table .custom-table-wrapper {
            max-height: calc(100vh - 140px);
        }
    }
}