@import '../../assets/scss/variables.scss';
.card {
    border-radius: 12px;
    border: 1px solid var(--color-border);
    background: var(--color-white);
    box-shadow: $shadow;
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    @include max-screen($desktop-xlarge) {
        padding: 15px;
     }
     @include max-screen($mobile) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
     }
  
    p {
      color: var(--color-textLight);
      font-size: 14px;
      font-weight: 500;
    }
  
    h2 {
      font-size: 22px;
      font-weight: 600;
      @include max-screen($desktop-xlarge) {
        font-size: 20px;
     }
     @include max-screen($desktop-large) {
        font-size:18px;
     }
    }
  
    &__info {
      width: 100%;
      gap: 5px;
      @include max-screen($mobile) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }