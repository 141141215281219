@import '../../assets/scss/variables.scss';
.tabs{
    nav{
        position: sticky;
        top: 80px;
        width: 250px;
            background-color: var(--color-white);
            box-shadow: $shadow2;
            border-radius: 10px;
            padding: 10px 0;
          height: calc(100vh - 125px);
          overflow-y: auto;
            @include max-screen($mobile) {
                width: 100%;
                overflow-x: auto;
                padding: 5px;
                position: static;
                height: auto;
            }
            & > ul{
            
            
           
                gap: 10px;
                @include max-screen($mobile) {
                    width: 100%;
    
                    min-height: 0;
                    flex-direction: row;
                    display: flex;
                    white-space: nowrap;
                }
                li{
                    margin: 5px 0;
                    @include max-screen($mobile) {
                        margin: 0;
                    }
                    a{
                        padding: 8px 12px;
                        min-height: 0;
                        font-size: 14px;
                        font-weight:600;
                        color: var(--color-textLight);
                        text-decoration: none;
                        text-align: left;
                        align-items: center;
                        text-transform: capitalize;
                        max-width: 100%;
                        display: flex;
                        gap: 10px ;
                        svg{
                            width: 16px;
                            height: 16px;
                        }
                        &.active, &:hover{
                            background-color: var(--color-border);
                            color: var(--color-primary);
                            
                        }
                        @include max-screen($mobile) {
                            min-width: 0;
                            padding: 8px ;
                        }
                    }
                    &.tabdisable{
                        cursor: not-allowed;
                        opacity:0.6; 
                        &:hover{
                            a{
                                pointer-events: none;
                                 background-color: transparent;
                            }
                        };
                    }
                }
            
            }
    }

        &__info{
            width: calc(100% - 250px);
            padding-left: 30px;
            @include max-screen($mobile) {
                width: 100%;
             padding: 0;
             margin-top: 20px;
            }
            :global{
                .noData{
                    min-height: calc(100vh - 192px);
                    figure{
                        margin-bottom:0;
                    }
                }
                .custom-table  .custom-table-wrapper {
                    max-height: calc(100vh - 180px);
                }
            }
        }
    }
