@import '../../../assets/scss/variables.scss';
.leadsTabInfo{
    &__header{
        padding: 24px;
        border-bottom: 1px solid var(--color-border);
        h2{
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text);
            gap: 8px;
        }
    }
    table tr th {
        background-color: var(--color-bgLight);
    }
}